import React from 'react';
import './style.scss';
import Images1 from '../../../assets/images/ring.jpg';
import Images2 from '../../../assets/images/anya.jpg';
import { CheackIcon } from '../../../assets/icons/Icons';

function Index() {
    const cardarr = [
        {
            images: Images1,
            text1: 'Ring Concierge',
            text2: 'Here\'s a glimpse of the incredible work our Shopify developers have done for a nine-figure brand.',
            text3: 'Highlighted Task',
            lists: [
                'Customize Shopify Plus theme layout',
                'Setup One Page Checkout ',
                'Setup, Integrate, and customize third-party apps',
                'Optimize mobile responsiveness',
                'Implement SEO best practices',
                'Develop custom Shopify apps',
                'Create custom checkout experiences',
                'Enhance site speed and performance',
                'EPR integration',
                'Customize product pages with unique features',
                'Automate order processing and fulfillment requests',
                'Setup 3PL fulfillment integration',
                'Build custom reporting tools',
                'Create a blog within Shopify store',
                'Implement GDPR compliance features',
                'Set up back in stock notifications',
                'Customize invoice and order templates',
                'Implement advanced shipping rules',
                'Fix ADA Compliance issues',
                'Move my store to Hydrogen / Headless commerce'

            ],
            link: 'Get Started'
        },
        {
            images: Images2,
            text1: 'Anya',
            text2: 'Our developers have been instrumental from the start, helping it grow into a thriving 7+ figure brand.',
            text3: 'Highlighted Task',
            lists: [
                'Subscription integration via Recharge',
                'Built custom Shopify theme  ',
                'Setup, Integrate, and customize third-party apps',
                'Optimize mobile responsiveness',
                'Implement SEO best practices',
                'Enhance site speed and performance',
                'Customize product pages with unique features',
                'Setup 3PL fulfillment integration',
                'Build custom reporting tools into Airtable',
                'Create a blog within Shopify store',
                'Customize invoice and order templates',
                'Implement advanced shipping rules',

            ],
            link: 'Get Started'
        }
    ];

    return (
        <>
            <div id='capabilities' className='main__empty__div' />
            <div className='cabilities__cards__section'>
                <div className='custom__container'>
                    <div>
                        <p className='capablities__heading'>See What’s Possible</p>
                        <p className='capablities__text' >
                            Founders of multi-million dollar brands rely on experts like us—Shopify nerds who handle all tech needs, big or small. Here's a sample of tasks we've accomplished for other Shopify stores.
                        </p>
                    </div>

                    <div className='capablities__main'>
                        {cardarr.map((card, index) => (
                            <div className='cabilties__img' key={index}>
                                <img src={card.images} alt='' />
                                <div>
                                    <p className='list__heading'>{card.text1}</p>
                                    <p className='list__text'>{card.text2}</p>
                                    <p className='name__des'>{card.text3}</p>
                                    {card.lists.map((listItem, listIndex) => (
                                        <div className='feature__list__item' key={listIndex}>
                                            <div>
                                                <CheackIcon />
                                            </div>
                                            <div>
                                                <p className='list__style'>{listItem}</p>
                                            </div>
                                        </div>
                                    ))}
                                    {/* <div className='link__conatiner'>
                                    <button className='link__style'>{card.link}</button>
                                </div> */}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Index;
