import React, { useRef, useState } from 'react'
import './style.scss'
import { NUmberIcon, NumberThreeIcon, NumberTwoIcon, PauseIcon, PlayIcon } from '../../../assets/icons/Icons'
import mainVideo from '../../../assets/videos/webmainvideo.mp4'
import mainVideoMob from '../../../assets/videos/webmainvideomob.mp4'
import videoThumbnail from '../../../assets/images/videoThumbnail.png'

function Index({ width }) {

    const [play, setPlay] = useState(false)
    const videoRef = useRef()

    const playPause = () => {
        if (play) {
            videoRef.current?.pause()
        } else {
            videoRef.current?.play()
        }
        setPlay(!play)
    }

    const arrbtncard = [
        {
            text1: 'Subscribe to a Plan',
            text2: 'Begin by selecting the plan that best suits your needs.',
            icon: <NUmberIcon />
        },
        {
            text1: 'Setup Call',
            text2: "Arrange your setup call to review your progress, connect your accounts, and establish your Trello board for task management. We'll also assist you in creating your first tasks.",
            icon: <NumberTwoIcon />
        },
        {
            text1: 'Creating Task',
            text2: 'Simply drop your tasks into the system, watch them come to life on your store, and repeat the process as needed.',
            icon: <NumberThreeIcon />
        }

    ]

    return (
        <>
            <div id='howItWorks' className='' />
            <div className='custom__container'>
                <div className='work__main'>
                    <div>
                        <button className='workit__btn'>How it works</button>
                    </div>
                    <h2 className='process__heading'>What to expect when working with us</h2>
                    <div className='process__card__section'>
                        {
                            arrbtncard.map((e, i) => {
                                return (
                                    <div className='process__card' key={i}>
                                        <div>
                                            {e.icon}
                                        </div>
                                        <div>
                                            <p className='proccess__card__title'>{e.text1}</p>
                                        </div>
                                        <div>
                                            <p className='proccess__card__description'>
                                                {e.text2}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                    <div className=' video__conatiner'>
                        <div className='video__box'>
                            <video poster={videoThumbnail} ref={videoRef} style={{ width: "100%" }}>
                                <source src={width < 525 ? mainVideoMob : mainVideo} type='video/mp4' />
                            </video>
                            <button onClick={playPause} className='play__pause__btn'>
                                {!play ? <PlayIcon /> :
                                    <PauseIcon />}
                            </button>
                        </div>
                    </div>


                </div>

            </div>
        </>
    )
}

export default Index