import React from 'react'
import './style.scss'
import { ReviewCard } from '../../../components'
import review1 from '../../../assets/images/reviewsImgs/review1.jpg'
import review2 from '../../../assets/images/reviewsImgs/review2.jpg'
import review3 from '../../../assets/images/reviewsImgs/review3.jpg'
import review4 from '../../../assets/images/reviewsImgs/review4.jpg'
import review5 from '../../../assets/images/reviewsImgs/review5.jpg'
import review6 from '../../../assets/images/reviewsImgs/review6.jpg'
import review7 from '../../../assets/images/reviewsImgs/review7.jpg'
import review8 from '../../../assets/images/reviewsImgs/review8.jpg'

function index() {

    const cardarr = [
        {
            img: review1,
            text1: 'Commerce Squad has become our trusted tech team, our entire team relies on them to run our Shopify Plus stores, retail, ERPs and everything in between. We love working with them.',
            text2: '- Ring Concierge'
        },
        {
            img: review2,
            text1: 'Ours is a dynamic business and things change all the time. Their 24/7 support makes Commerce Squad crucial to our growth. We couldn’t have launched without them.',
            text2: '- Rootly, c/o HairClub'
        },
        {
            img: review3,
            text1: 'I used to rely on just my IG, then with Commerce Squad, our website became a vibrant, interactive space. The positive feedback has been overwhelming.',
            text2: '- Eating NYC'
        },
        {
            img: review4,
            text1: 'We’ve been most impressed by the high quality of the work, the turnaround, and how someone is always here to provide insights on what we’re facing as we grow.',
            text2: '- Anya Labs'
        },
        {
            img: review5,
            text1: 'We needed custom coded apps for our Shopify store - Commerce Squad helped transform our customer engagement and satisfaction by helping us do more.',
            text2: '- Nailstry'
        },
        {
            img: review6,
            text1: 'The key to startup growth is extremely talented people, and if you can get them at fractions of usual cost, even better. We found both with Commerce Squad.',
            text2: '- Mint Mobile, c/o T-Mobile'
        },
        {
            img: review7,
            text1: 'We’ve really enjoyed working with our tech team. eCommerce expertise for international markets from Commerce Squad is crucial for any brand expanding to new markets.',
            text2: '- Suta'
        },
        {
            img: review8,
            text1: 'From before day one, I have relied on our team from Commerce Squad for things from setup to integrations to anything too technical I need help on. I wouldn’t do it any other way.',
            text2: '- LeTien'
        },
    ]

    return (

        <div className='shopify__reviews__secion'>
             <div className='custom__container'>
            <div className='reviews__cards__section'>
            {
                    cardarr.map((e, i) => {
                        return <ReviewCard key={i} data={e} />
                    })
                }
            </div>
            </div>
        </div>
    )
}

export default index