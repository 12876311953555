import React from 'react';
import image1 from '../../../assets/images/girlWithLaptop.jpg';
import image2 from '../../../assets/images/figma.jpg';
import image3 from '../../../assets/images/conference.jpg'
import uiVideo from '../../../assets/videos/hero-ui-mobile-light.webm'
import './style.scss';
import { ListTickIcon } from '../../../assets/icons/Icons';

export default function Index() {
    const arrcard = [
        {
            images: image1,
            title: 'You’re wasting your time',
            description: 'Founders often find themselves doing things they don’t need to, like:',
            features: [
                "Customizing their store",
                "Being limited by app capabilities",
                "Troubleshooting integrations"
            ]
        },
        {
            images: image2,
            title: "...but you don't need to",
            description: 'Founders DIY because its hard to find affordable experts.',
            features: [
                "Shopify experts are expensive",
                "Managing outside teams and workflows is difficult",
                "Results are often disappointing",
            ],
            isVideo: true,
            videoUrl:uiVideo
        },
        {
            images: image3,
            title: 'That’s where we come in',
            description: "A simple, turn-key tech team for your brand handling everything so you don't need to.",
            features: [
                "<span>Top Shopify experts, guaranteed.</span> Access to premier Shopify professionals, ensuring high-quality results every time.",
                "<span>We work where you work.</span> The devs act as if they are part of your team and remove the headache of working across multiple tools. And you will never need to ask or wait on an update on your tasks.",
                "<span>Affordable with a money-back guarantee.</span> No surprises or hidden fees, and a no-questions-asked 30-day satisfaction guarantee.",
            ],
            listType: "tick"
        },
    ];

    return (
        <div className='whyus__main_container'>
            <div className='custom__container'>
                {arrcard.map((e, i) => (
                    <div className={`${i % 2 === 0 ? "reverse__on__mob" :""} whuyus__card_main`} key={i}>
                        {(i % 2 === 0) ? (
                            <>
                                <div className={`why__us__sub__div text__section ${i === arrcard.length - 1 ? 'last_card' : ''}`}>
                                    <h2 className='whtus_text'>{e.title}</h2>
                                    <p className='whyus__des'>{e.description}</p>
                                    {e?.features?.map((v, i) => {
                                        return e?.listType === "tick" ? <div className='feature__with__tick'>
                                            <div>
                                                <ListTickIcon />
                                            </div>
                                            <div>
                                                <p className='whyus__list__item m-0' dangerouslySetInnerHTML={{ __html: v }} key={i}></p>
                                            </div>
                                        </div>
                                            :
                                            <p className='whyus__list__item' key={i}>{v}</p>
                                    })}
                                </div>
                                <div className='why__us__sub__div'>
                                    <img src={e.images} alt="why us" className='why_card_img' />
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='why__us__sub__div'>
                                    {e?.isVideo ? <video autoPlay muted loop className='why_card_img'>
                                        <source type='video/webm' src={e?.videoUrl}>
                                        </source>
                                    </video> :<img src={e.images} alt="why us" className='why_card_img' />}
                                </div>
                                <div className={`why__us__sub__div text__section ${i === arrcard.length - 1 ? 'last_card' : ''}`}>
                                    <h2 className='whtus_text'>{e.title}</h2>
                                    <p className='whyus__des'>{e.description}</p>
                                    {e?.features?.map((v, i) => {
                                        return <p className='whyus__list__item' key={i}>{v}</p>
                                    })}
                                </div>
                            </>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}
