import React from 'react';
import './footer.scss';
import footerlogo from '../../assets/images/svg/logoWhite.svg';
import linkedinlogo from '../../assets/images/svg/linkedin.svg'
import { info, links } from '../../constants/data';
import { goToRef } from '../../functions/common';

function Index({ benefitRef, serviceRef, pricingRef, faqRef }) {
  const references = {
    benefitRef,
    serviceRef,
    pricingRef,
    faqRef
  }



  return (
    <div className='footer__main'>
      <div className='footer__block'>
        <div className='logo__and__links'>
          <div className='col-md-3 logo__section'>
            <img src={footerlogo} alt='positive us' />
            <a rel='noreferrer' target='_blank' href={info?.linkedinLink} className='linkedin__logo__for__tab'>
              <img src={linkedinlogo} alt='linkedin' />
            </a>
          </div>
          <div className='col-md-7 links__section'>
            {links?.map((v, i) => {
              return <button onClick={()=> goToRef(references[v?.ref])} key={i}>
                {v?.name}
              </button>
            })}
          </div>
          <div className='col-md-2 d-flex justify-content-end linkedin__section'>
            <a rel='noreferrer' target='_blank' href={info?.linkedinLink}>
              <img src={linkedinlogo} alt='linkedin' />
            </a>
          </div>
        </div>
        <div className='contact__section'>
          <p className='contact__us__heading'>
            Contact us:
          </p>
          <div className='contact__detail__list'>
            <p className='contact__item'>Email: <a href={`mailto:${info?.email}`}>{info?.email}</a> </p>
            <p className='contact__item'>Phone: <a href={`tel:${info?.phone?.replace("-", "")}`}>{info?.phone}</a></p>
            <p className='contact__item'>Address: One World Trade Center Fulton St 88th flr, New York, NY 10007</p>
          </div>
        </div>
        <div className='center__line' />
        <div className='copyright__section'>
          <p className='copyright__text'>
            © Relibit Labs LLC. All Rights Reserved.
          </p>
          <div className='privary__link__section'>
            <a rel='noreferrer' target='_blank' href={info?.privacyPolicyLink} >Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index
