import React, { useState, useEffect } from 'react'
import { Normalbtn } from '..'
import logo from '../../assets/images/svg/newLogo.svg'
import { HashLink } from 'react-router-hash-link'
import './style.scss'
import { CloseIcon, MenuIcon } from '../../assets/icons/Icons'
import { Link } from 'react-router-dom'
import { info } from '../../constants/data'

function Index({ width, onlyLogo }) {

    const [hasScrolledTo, setHasScrolled] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 84) {
                setHasScrolled(true);
            } else {
                setHasScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const links = [
        {
            name: "How it Works",
            link: "#howItWorks"
        },
        {
            name: "Capabilities",
            link: "#capabilities"
        },
        {
            name: "Pricing",
            link: "#pricing"
        },
        {
            name: "FAQs",
            link: "#faqs"
        },
    ]

    const toggleMenu = () => setOpen(o => !o)

    return (
        <>
            <div className='main__empty__div' />
            {onlyLogo ? <div style={hasScrolledTo ? { backgroundColor: "white", zIndex: 999 } : {}} className='main_nav'>
                <div className='nav__container'>
                    <a href="/">
                        <img src={logo} alt='' />
                    </a>
                </div>
            </div> : width > 990 ? <div style={hasScrolledTo ? { backgroundColor: "white", zIndex: 999 } : {}} className='main_nav'>
                <div className='nav__container'>
                    <a href="/">
                        <img src={logo} alt='' />
                    </a>
                    <div className='links__and__btn__section'>
                        <div className='links__section'>
                            {links?.map((v, i) => {
                                return <HashLink className='link_style' key={i} to={v?.link}>
                                    {v?.name}
                                </HashLink>
                            })}
                        </div>
                        <div className='d-flex gap-3 align-items-center'>
                            <Link target='_blank' className='get__started__btn login__btn' to={info?.customerStripeLoginUrl}>
                                Login
                            </Link>
                            <HashLink className='get__started__btn' to={"#pricing"}>
                                <Normalbtn label="Start Free Trial" />
                            </HashLink>
                        </div>
                    </div>

                </div>
            </div> :
                <div style={hasScrolledTo ? { backgroundColor: "white", zIndex: 999 } : {}} className='flex-wrap main_nav d-flex justify-content-between'>
                    <div style={{ height: 84 }} className='d-flex justify-content-between w-100 align-items-center'>
                        <div>
                            <a href="/">
                                <img src={logo} alt='' />
                            </a>
                        </div>
                        <div className='d-flex gap-1'>
                            <HashLink className='get__started__btn' to={"#pricing"}>
                                <Normalbtn mobNav label="Start Free Trial" />
                            </HashLink>
                            <button onClick={toggleMenu} className='menu__btn'>
                                {!open ? <MenuIcon color={"#000"} />
                                    : <CloseIcon />}
                            </button>
                        </div>
                    </div>
                    {<div className={`mobile__menu__container ${open ? "active__menu" : ""}`}>
                        <div className='mob__sub__div'>
                            <div className='mobile__menu__section'>
                                {links?.map((v, i) => {
                                    return <div key={i}>
                                        <HashLink onClick={toggleMenu} className='link_style' to={v?.link}>
                                            {v?.name}
                                        </HashLink>
                                    </div>
                                })}
                                <Link target='_blank' className='get__started__btn login__btn' to={info?.customerStripeLoginUrl}>
                                Login
                                </Link>
                            </div>
                            <div>

                            </div>
                        </div>
                    </div>}
                </div>
            }
        </>
    )
}

export default Index