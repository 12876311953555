import React, { useEffect, useState } from 'react'
import './style.scss'
import { Navber } from '../../components'
import { CapabilitiesCard, FaqsShopify, Fotter, Main, Plans, ProjectCards, Review, Whyus, Workingsetup } from '../../layouts'
import { homePageVisit } from '../../functions/fbp'


const scrollToHash = () => {
  if (window.location.hash) {
    const element = document.getElementById(window.location.hash.substring(1));
    window.scrollTo(element.offsetTop, element.offsetTop);
  }
};

function Index() {

  const [width, setWidth] = useState(window?.innerWidth)

  useEffect(() => {

    setTimeout(() => {
      scrollToHash();
    }, 700)

    homePageVisit()
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='shopify__screen position-relative overflow-hidden'>
      <Navber width={width} />
      <Main />
      {width > 525 ? <ProjectCards /> : ""}
      <Review />
      <Whyus />
      <Workingsetup width={width} />
      <CapabilitiesCard />
      <Plans />
      <FaqsShopify width={width} />
      <Fotter />
    </div>
  )
}

export default Index