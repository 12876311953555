import React from 'react'
import './style.scss'
import { NUmberIcon, NumberTwoIcon } from '../../../assets/icons/Icons'
import { info } from '../../../constants/data'
function index({email}) {

    const whatNext = [
        {
            title: "Watch welcome video",
            description: "Thanks for signing up, we’re exited to work with you. We created a short (we promise it’s not too long) video for you to hear from our founder.",
            img: <NUmberIcon />
        },
        {
            title: "Schedule Setup Call",
            description: `You can schedule a call using this <a target="_blank" rel="noreferrer" href=${info?.commercesquadCalendlyUrl}>Calendly link</a>. During our call, we'll discuss your project details and arrange the necessary access to get started.`,
            img: <NumberTwoIcon />
        },
    ]

    return (
        <div className='custom__container'>
            <div className='thanks__hero'>
                <div className='welcome__tag'>
                    Welcome
                </div>
                <h2 className='get__started__heading'>
                    Let’s get started
                </h2>
                <p className='get__started__description'>
                    We've emailed the next steps to {email}, but you can also find them outlined below.
                </p>
                <div className='what__next__cards__section'>
                    {whatNext?.map((e, i) => {
                        return (
                            <div className='what__next__card' key={i} >
                                <div>{e?.img}</div>
                                <div>
                                    <h3>{e?.title}</h3>
                                    <p dangerouslySetInnerHTML={{ __html: e?.description }}></p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default index