import React from 'react';
import './style.scss';
import { CalendlyPopup, NormalDarkbtn } from '../../../components';
import images from '../../../assets/images/cheackpng.png';
import unlimited from '../../../assets/images/svg/features/unlimitedReq.svg'
import superFast from '../../../assets/images/svg/features/superFast.svg'
import topTalent from '../../../assets/images/svg/features/topTalent.svg'
import fixedPlan from '../../../assets/images/svg/features/fixedPlan.svg'
import grow from '../../../assets/images/svg/features/grow.svg'
import unique from '../../../assets/images/svg/features/unique.svg'
import { info } from '../../../constants/data';
import { Link } from 'react-router-dom';

function Index() {
    const arrbtncard = [
        {
            text1: 'Subscribe to a Plan',
            text2: "Add as many task or feature requests to your Trello board as you'd like.",
            icon: unlimited
        },
        {
            text1: 'Super fast completion',
            text2: 'Get your tasks/features completed as quickly as a few hours to a few days.',
            icon: superFast
        },
        {
            text1: 'Fixed monthly plan',
            text2: 'You’re not paying for hours! Pay the same fixed price each month.',
            icon: fixedPlan
        },
        {
            text1: 'Top talent only ',
            text2: 'Our top Shopify experts tackle any task without the headache.',
            icon: topTalent
        },
        {
            text1: 'We grow with you',
            text2: 'Adjust your team size as needed, with the option to pause or cancel anytime.',
            icon: grow
        },
        {
            text1: 'Unique and yours',
            text2: 'All code is securely developed in your environment and is 100% yours.',
            icon: unique
        },
    ];

    const cardarr = [
        {
            header: '30-Day Money Back Guarantee',
            heading: 'Part-Time',
            button: 'Most Popular',
            Text1: "You've just started out or have a few tasks you need help with each month in order to reach growth goals.",
            Number: '$895',
            Numberrtext1: 'USD ',
            Numberrtext2: '/mo per dev ',
            listname: 'Standout features',
            list: [
                "Shopify Developer (assigned to you, shared with others)",
                "Product/Project Manager (assigned to you, shared with others)",
                "QA/Tester (assigned to you, shared with others)",
                "Work in developers timezone",
                "Easy credit-card payments",
                "Pause or cancel anytime"
            ],
            link: "https://buy.stripe.com/eVadTkauQd8XbNmeV0"
        },
        {
            header: '30-Day Money Back Guarantee',
            heading: 'Full-Time',
            Text1: "You're experiencing growth and must expand the tech team to elevate operations and scale further.",
            Number: '$1,995',
            Numberrtext1: 'USD ',
            Numberrtext2: '/mo per dev ',
            listname: 'Standout features',
            list: [
                "Shopify Developer (100% dedicated to your project)",
                "Product/Project Manager (assigned to you, shared with others)",
                "QA/Tester (assigned to you, shared with others)",
                "Work in your time zone",
                "Easy credit-card payments",
                "Pause or cancel anytime"
            ],
            link: "https://buy.stripe.com/bIYg1sgTe9WL18IaEL"
        },
    ];

    return (
        <div id='pricing' className='custom__container'>
            <div className='plans__main__container'>
                <p className='plans__text'>Pricing and Plans</p>
                <div className='plan__card__container'>
                    {cardarr.map((e, i) => (
                        <div className='plan_card_box' key={i}>
                            <div className='box__sizing'>
                                <div className='card__header' style={{ background: i === 1 ? '#79DFFF' : '#D0F224' }}>
                                    <p className='header_text'>{e.header}</p>
                                </div>
                                <div className='card__text__section__2'>
                                    <div className='header__align'>
                                        <div>
                                            <p className='heading__text'>{e.heading}</p>
                                        </div>
                                        {i !== 1 && (
                                            <div>
                                                <button className='plans__btn'>{e.button}</button>
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <p className='header__text__style'>{e.Text1}</p>
                                    </div>
                                    <div className='card__price__section'>
                                        <div>
                                            <p className='price__text'>{e.Number}</p>
                                        </div>
                                        <div>
                                            <p className='number__text'>{e.Numberrtext1}</p>
                                            <p className='number__text'>{e.Numberrtext2}</p>
                                        </div>
                                    </div>
                                    <div className='border__div' />
                                    <div className='list__section'>
                                        <p className='list___name'>{e.listname}</p>
                                        <div>
                                            {e.list.map((item, idx) => (
                                                <div key={idx} className='list__item'>
                                                    <img src={images} alt='check icon' />
                                                    <p className='list__text'>{item}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='btn__section'>
                                        <Link to={e?.link} target='_blank' >
                                            <NormalDarkbtn getStarted label="Try free for 30 days" />
                                        </Link>
                                        <div>
                                            <CalendlyPopup classes="link__style px-2" url={info?.commercesquadCalendlyUrl} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='workit__card__box'>
                {arrbtncard.map((e, i) => (
                    <div key={i} className='card__work_box'>
                        <div>
                            <div className='icon__box'>
                                <img src={e.icon} alt='' />
                            </div>
                            <div>
                                <p className='card__heading'>{e.text1}</p>
                                <p className='card__text'>{e.text2}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Index;
