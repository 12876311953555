import React from 'react';
import logo from '../../assets/images//svg/newLogo.svg';

const Index = () => {
    return (
        <>
            <div className='w-100 vh-100 d-flex align-items-center justify-content-center
            
            '>
            <img src={logo} alt='logo' />
            </div>
        </>
    )
}

export default Index