import card1 from '../assets/images/card1.jpg'
import card2 from '../assets/images/card2.jpg'
import card3 from '../assets/images/card3.jpg'
import unlimitedIcon from '../assets/images/svg/unlimited.svg'
import fastMeter from '../assets/images/svg/fastmeter.svg'
import growth from '../assets/images/svg/growth.svg'
import talent from '../assets/images/svg/talent.svg'
import unique from '../assets/images/svg/unique.svg'
import plan from '../assets/images/svg/plan.svg'
import brand1 from '../assets/images/svg/brand1.svg'
import brand2 from '../assets/images/svg/brand2.svg'
import brand3 from '../assets/images/svg/brand3.svg'
import brand4 from '../assets/images/svg/brand4.svg'


export const links = [
    {
        name: "Benefits",
        ref: "benefitRef"
    },
    {
        name: "Services",
        ref: "serviceRef"
    },
    {
        name: "Pricing",
        ref: "pricingRef"
    },
    {
        name: "FAQs",
        ref: "faqRef"
    },
]

export const cards = [
    {
        description: "A trend you can't afford to miss",
        image: card1,
        heading:"The Underrated Secret Behind Highly Profitable Commerce Businesses",
        link:"https://www.linkedin.com/posts/deepak-devjani_drake-related-the-official-website-of-drake-activity-7160476114040066049-sdSg?utm_source=share&utm_medium=member_desktop"
    },
    {
        description: "A shift in focus could be our biggest opportunity",
        image: card2,
        heading:"Why Losing VC Interest Might Just Be the Best Thing for E-Commerce Brands",
        link:"https://www.linkedin.com/posts/deepak-devjani_the-days-of-hiring-a-full-team-for-your-ecommerce-activity-7159404936387055616-CHJ2?utm_source=share&utm_medium=member_desktop"
    },
    {
        description: "Here’s why every brand needs to take a closer look",
        image: card3,
        heading:"Shopify reinvents a familiar model with a fresh perspective.",
        link:"https://www.linkedin.com/posts/deepak-devjani_httplalalandai-activity-7155697974520500225-_dHX?utm_source=share&utm_medium=member_desktop"
    },
]

export const featureCards = [
    {
        name: "Unlimited Request",
        description: "Add as many task or feature requests to your Trello board as you'd like.",
        icon: unlimitedIcon
    },
    {
        name: "Super fast completion",
        description: "Get your task or feature tickets completed as quickly as few hours to a few days.",
        icon: fastMeter
    },
    {
        name: "Fixed monthly plan",
        description: "You’re not paying for hours! Pay the same fixed price each month.",
        icon: plan
    },
    {
        name: "Top Talent Only ",
        description: "Add as many task or feature requests to your Trello board as you'd like.",
        icon: talent
    },
    {
        name: "We grow with you",
        description: "Get your task or feature tickets completed as quickly as few hours to a few days.",
        icon: growth
    },
    {
        name: "Unique and yours",
        description: "You’re not paying for hours! Pay the same fixed price each month.",
        icon: unique
    },
]

export const pricingCards = [
    {
        name: "Basic",
        description: "Founders who are starting out (MVP) and want to get an idea out the door.",
        price: "$495",
        features: [
            "1 task at a time",
            "Sr Shopify Engineer on demand",
            "IT Engineer on demand",
            "Product / Project Manager on demand",
            "Same-day response times",
            "Easy credit-card payments",
            "Pause or cancel anytime",
        ],
        type: "fix",
        link:"https://buy.stripe.com/28o7uWbyU2ujaJi008"
    },
    {
        name: "Standard",
        description: "You've achieved product market fit and are consistently making changes to reach growth.",
        price: "$895",
        features: [
            "Many task at a time",
            "1 Sr. Shopify Engineer (assigned to you, shared with others)",
            "1 Assigned Product/Project Manager (assigned to you, shared with others)",
            "IT Engineer on demand",
            "4 hour response time",
            "Easy credit-card payments",
            "Pause or cancel anytime",
        ],
        type: "fix",
        link:"https://buy.stripe.com/eVadTkauQd8XbNmeV0"
    },
    {
        name: "Advanced",
        description: "You're experiencing growth and must expand the tech team to elevate operations and scale further.",
        price: "$1,995",
        features: [
            "Many task at time",
            "1 Sr. Shopify Engineer (dedicated to you)",
            "1 Product/Project Manager (assigned to you, shared with others)",
            "IT Engineer on demand",
            "1 QA/Tester (assigned to you, shared with others)",
            "1 UI/UX designer (assigned to you, shared with others)",
            "1 hour response time",
            "Easy credit-card payments",
            "Pause or cancel anytime",
        ],
        type: "fix",
        link:"https://buy.stripe.com/bIYg1sgTe9WL18IaEL"
    },
]

export const tasks = [
    {
        name: "Customize Shopify theme layout"
    },
    {
        name: "Setup, Integrate, and customize third-party apps"
    },
    {
        name: "Optimize mobile responsiveness"
    },
    {
        name: "Implement SEO best practices"
    },
    {
        name: "Develop custom Shopify apps"
    },
    {
        name: "Set up product variants and options"
    },
    {
        name: "Create custom checkout experiences"
    },
    {
        name: "Enhance site speed and performance"
    },
    {
        name: "Design and deploy marketing pop-ups"
    },
    {
        name: "Configure multi-language support"
    },
    {
        name: "Integrate with social media platforms"
    },
    {
        name: "EPR intergrations"
    },
    {
        name: "Setup Checkout extensibility"
    },
    {
        name: "Setup One Page Checkout"
    },
    {
        name: "Setup compliance for Klaviyo"
    },
    {
        name: "Setup custom tiered discounts"
    },
    {
        name: "Setup free gift add-ons for purchases"
    },
    {
        name: "Customize product pages with unique features"
    },
    {
        name: "Integrate email marketing platforms"
    },
    {
        name: "Automate order processing and fulfillment requests"
    },
    {
        name: "Setup 3PL fulfillment integration"
    },
    {
        name: "Build custom reporting tools"
    },
    {
        name: "Implement customer review systems"
    },
    {
        name: "Create a blog within Shopify store"
    },
    {
        name: "Develop loyalty and rewards programs"
    },
    {
        name: "Integrate live chat support"
    },
    {
        name: "Customize 404 error page"
    },
    {
        name: "Implement GDPR compliance features"
    },
    {
        name: "Set up back in stock notifications"
    },
    {
        name: "Customize invoice and order templates"
    },
    {
        name: "Implement advanced shipping rules"
    },
    {
        name: "Create gift card functionality"
    },
    {
        name: "Fix ADA Compliance issues"
    },
    {
        name: "Integrate third-party payment gateways "
    },
    {
        name: "Move my store to Hydrogen / Headless commerce"
    },
    {
        name: "+ more"
    }
]

export const faqData = {
    serviceFaq: [
        {
            question: "What Shopify tasks can I request?",
            answer: "Absolutely any Shopify task! We're well-versed in all Shopify aspects and eager to tackle any challenge."
        },
        {
            question: "How do I submit my Shopify requests?",
            answer: "Submit them through a dedicated Trello board we set up for you after signing up. If you already have a Trello board, you can invite us to join it."
        },
        {
            question: "Can I request unlimited Shopify tasks?",
            answer: "Yes, you can. After submitting your request, you can prioritize it, and we'll keep you updated on the task's progress and current stage."
        },
        {
            question: "Are there any tasks related to Shopify that you don’t cover?",
            answer: "Our developers are skilled in the backend and interacting with various 3rd party APIs, covering most needs beyond Shopify's liquid language."
        },
        {
            question: "Can I request custom Shopify app development?",
            answer: "Certainly! We specialize in both public and private Shopify app development, a key service as your business scales."
        },
        {
            question: "Can you integrate third-party apps into my Shopify store for enhanced functionality?",
            answer: "Yes, we frequently integrate third-party apps into Shopify stores, anticipating this need as your business grows and becomes more complex."
        },
    ],
    developmentFaq: [
        {
            question: "How quickly will I receive my Shopify feature updates?",
            answer: "Updates are typically completed within a day, hours for simpler tasks, and up to a week for complex ones. You'll have Trello access to view the devs' estimated hours and due dates for all tasks."
        },
        {
            question: "Who will be working on my Shopify tasks?",
            answer: "Your tasks will be handled by Senior Shopify engineers experienced in Liquid, GraphQL, Hydrogen, and other Shopify frameworks, supervised by a dedicated Project Manager to keep everything on track."
        },
        {
            question: "Is there a limit to the number of Shopify tasks I can have in the queue?",
            answer: "No limit! Add as many tasks as needed, but we'll need your help to prioritize them, understanding that priorities can change."
        },
        {
            question: "Can I work with the same developer for all my Shopify tasks?",
            answer: "Yes, we assign the same developer for continuity and efficiency, ensuring familiarity with your code and industry nuances."
        },
        {
            question: "How do you handle urgent or expedited Shopify development requests?",
            answer: "For urgent requests, we have processes in place. Just notify us via Trello, Slack, or Teams, and we'll expedite the task."
        },
    ],
    billingFaq: [
        {
            question: "How does the billing cycle work for Shopify development services?",
            answer: "Billing is simple: a monthly subscription fee, much like Shopify, with no fixed costs or scope."
        },
        {
            question: "Do you offer refunds if I’m unhappy with the Shopify services provided?",
            answer: "Yes, there's a 30-day satisfaction guarantee. If you're not happy in the first month, we'll refund your money."
        },
        {
            question: "Can I pause my subscription if I don’t have any current Shopify tasks?",
            answer: "Absolutely, you can pause your subscription whenever needed. We appreciate a 15-day notice before your next billing cycle."
        },
        {
            question: "Are there any contracts or long-term commitments required?",
            answer: "No long-term commitments or contracts. You have the freedom to pause or cancel at any time."
        }
    ],
    satisfactionFaq: [
        {
            question: "What happens if I’m not satisfied with the work done?",
            answer: "We offer a 30-day satisfaction guarantee. If you're unhappy in the first month, you'll get a refund. For any dissatisfaction in the future, let us know and we'll work to make it right."
        },
        {
            question: "Can I add additional team members to manage Shopify requests?",
            answer: "Absolutely, the more, the better. Feel free to invite any team members involved with your Shopify store."
        },
        {
            question: "Can you optimize Shopify stores for mobile users?",
            answer: "Yes, we prioritize mobile optimization as it's often the primary traffic source for stores. Our approach is 'mobile-first'."
        },
        {
            question: "Can you help improve my Shopify store’s loading speed and performance?",
            answer: "Definitely. We're obsessed with speed and always consider it when building features. We strive to optimize your store for the fastest possible performance."
        },
    ]
}

export const faqTabs = [
    {
        tabName: "Service Scope",
        objectKey: "serviceFaq"
    },
    {
        tabName: "Development Process",
        objectKey: "developmentFaq"
    },
    {
        tabName: "Billing",
        objectKey: "billingFaq"
    },
    {
        tabName: "Satisfaction",
        objectKey: "satisfactionFaq"
    },
]

export const faqTabs2 = [
    {
        tabName: "General",
        objectKey: "serviceFaq"
    },
    {
        tabName: "Development Process",
        objectKey: "developmentFaq"
    },
    {
        tabName: "Billing",
        objectKey: "billingFaq"
    },
    {
        tabName: "Satisfaction Guarantee",
        objectKey: "satisfactionFaq"
    },
]

export const info = {
    email: "hello@reliablebits.io",
    phone: "212-729-4229",
    linkedinLink:"https://www.linkedin.com/company/reliablebits/",
    privacyPolicyLink:"https://reliablebits.io/privacy-policy",
    calendlyUrl:"https://calendly.com/ddx/sync25?embed_type=PopupWidget&embed_domain=1",
    commercesquadCalendlyUrl:"https://calendly.com/ddx/commerce-squad-onboarding",
    customerStripeLoginUrl:process.env.REACT_APP_STRIPE_CUSTOMER_LOGIN_URL,
}

export const brands = [
   {
    src: brand1
   },
   {
    src: brand2
   },
   {
    src: brand3
   },
   {
    src: brand4
   },
]

export const features = [
    {
      text: "Subscribe to a plan & request as many features or tasks as you'd like."
    },
    {
      text: "Work is completed directly in your Shopify store within a few hours or days."
    },
    {
      text: "We'll revise and update until you're 100% satisfied. Rinse & Repeat"
    }
  ]