import React, { useState } from 'react'
import { faqData, faqTabs2 } from '../../../constants/data'
import { Collapse, Select, } from 'antd'
import { BottomArrowIcon, MinusIconFaq2, PlusIconFaq2 } from '../../../assets/icons/Icons'
import './style.scss'

const Index = ({ faqRef, width }) => {

    const [activeTab, setActiveTab] = useState("serviceFaq")
    const changeTab = (tab) => setActiveTab(tab?.objectKey)

    const tabOptions = faqTabs2?.map((v) => {
        return {
            label: v?.tabName,
            value: v?.objectKey
        }
    })

    const handleProvinceChange = (value) => {
        setActiveTab(value)
    }
    return (
        <>
            <div id='faqs' className='main__empty__div' />
            <div ref={faqRef} className='faq__main__2'>
                <div className='custom__container'>
                    <div>
                        <p className='fotter__text'>
                            Frequently asked questions
                        </p>
                    </div>
                    {width > 768 ? <div className='faq__tabs__container'>
                        {faqTabs2?.map((v, i) => {
                            return <button key={i} onClick={() => changeTab(v)} className={`tab__main ${v?.objectKey === activeTab ? "active__tab" : ""}`}>
                                {v?.tabName}
                            </button>
                        })}
                    </div> :
                        <div className='tab__select__section_2'>
                            <Select
                                style={{ fontSize: 30 }}
                                className='tab__select'
                                defaultValue={tabOptions[0]}
                                onChange={handleProvinceChange}
                                options={tabOptions}
                                suffixIcon={<BottomArrowIcon />}
                                popupClassName='tab__select__popup'
                            />
                        </div>}
                    <div className='shopify__faq__section'>
                        <Collapse
                            className='shopify__faqs'
                            collapsible="header"
                            defaultActiveKey={['0']}
                            expandIconPosition='right'
                            expandIcon={({ isActive }) => <button className='toggle__btn'>{isActive ? <MinusIconFaq2 /> : <PlusIconFaq2 />}</button>}
                            items={faqData[activeTab]?.map((v, i) => {
                                return (
                                    {
                                        key: i,
                                        label: v?.question,
                                        children: <div className='collapse__content'>
                                            <p><p>{v?.answer}</p></p>
                                        </div>,
                                    }
                                )
                            })}
                        />
                    </div>


                </div>
            </div>
        </>
    )
}

export default Index