import React from 'react'
import './style.scss'
import { info } from '../../../constants/data'


const Index = () => {



    return (
        <div className='fotter__main__box'>
            <div className='custom__container'>
                <div className='fotter__border'>
                    <div className='copy__right__section'>
                        <div>
                            <p>© Copyright 2024 • Relibit Labs, LLC </p>
                        </div>
                        <div>
                            <a target='_blank' rel="noreferrer" href={info?.privacyPolicyLink}>Privacy Policy</a>
                        </div>
                    </div>
                    <div>
                        <p>
                            A Reliable Bits product
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index