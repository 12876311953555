import React from 'react'
import cardimg from '../../../assets/images/projectcard.jpg'
import cardimg2 from '../../../assets/images/projectcard2.jpg'
import cardimg3 from '../../../assets/images/carojectcard3.jpg'
import cardimg4 from '../../../assets/images/projectCard4.jpg'
import './style.scss'


function index() {

    const cardArr = [
        {
            img: cardimg
        },
        {
            img: cardimg2
        },
        {
            img: cardimg3
        },
        {
            img: cardimg4
        }
    ]
    return (
        <div className='custom__container'>
            <div className='project__card__section'>
                {cardArr.map((e, i) => (
                        <img  src={e.img} alt={`Card ${i}`} className='project__card__img' />
                ))}
            </div>
        </div>
    )
}

export default index