import React, { useEffect, useRef, useState } from 'react'
import { PauseIcon, PlayIcon } from '../../../assets/icons/Icons'
import thanksVideo from '../../../assets/videos/dd_ecommerce.mp4'
import thanksVideoMob from '../../../assets/videos/dd-ecommercemob.mp4'
import './style.scss';
import thanksVideoThumbnail from '../../../assets/images/thanksVideoThumbnail.png'

const Index = () => {

    const [play, setPlay] = useState(false)
    const [width, setWidth] = useState(window?.innerWidth)
    const videoRef = useRef()

    const playPause = () => {
        if (play) {
            videoRef.current?.pause()
        } else {
            videoRef.current?.play()
        }
        setPlay(!play)
    }



    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='custom__container'>
            <div className=' thanks__video__conatiner'>
                <div className='video__box'>
                    <video poster={thanksVideoThumbnail} ref={videoRef} style={{ width: "100%" }}>
                        <source src={width < 525 ? thanksVideoMob : thanksVideo} type='video/mp4' />
                    </video>
                    <button onClick={playPause} className='play__pause__btn'>
                        {!play ? <PlayIcon /> :
                            <PauseIcon />}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Index