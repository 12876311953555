import React from 'react'
import './style.scss'
function index(props) {
    const { label, onClick, mobNav, classes } = props;
  return (
    <div>
        <button onClick={onClick} className={`${classes && classes} ${mobNav ? "small__on__mob":""} btnnoraml_style`}>{label}</button>
    </div>
  )
}

export default index