import axios from "axios"
import { backendUrl } from "./config"

export const getUserDetails = async (sessionId) => {
    
    try {
        if (sessionId && sessionId !== "") {
            const userData = await axios.get(`${backendUrl}/api/v1/stripe/gettransactiondata/${sessionId}`)
            return {
                error: false,
                message: "User data fetched successfully",
                data: userData?.data ?? {}
            }
        } else {
            
            return {
                error: true,
                message: "Session Id is required",
                data: {}
            }
        }
    } catch (error) {
        return {
            error: true,
            message: error.message,
            data: {}
        }
    }
}