import React from 'react'
import './style.scss'

function NormalDarkbtn(props) {
  const { label, onClick, getStarted } = props;
  return (
    <div>
      <button onClick={onClick} className={`btn_style ${getStarted ? "get__started__btn" : ""}`}>{label}</button>

    </div>
  )
}

export default NormalDarkbtn