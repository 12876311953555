//add token
export const addToken = (token) => {
    localStorage.setItem("shopifydev__token", token);
};

//get token
export const getToken = () => localStorage.getItem("shopifydev__token");

//remove token
export const removeToken = () => localStorage.removeItem("shopifydev__token");

//add token
export const addCurrentToken = (token) => {
    localStorage.setItem("aha__i__token", token);
};