import React from 'react'
import './style.scss'
import { CalendlyPopup, Normalbtn } from '../../../components'
import { HashLink } from 'react-router-hash-link'
import { info } from '../../../constants/data'
function index() {
    return (
        <div className='custom__container'>
            <div>
                <div className='top__blur__section__left' />
                <div className='top__blur__right' />
            </div>
            <div className='main_box_container'>
                <div className='main__conatiner'>
                    <h1 className='main__heading'>Instantly get a top-tier Shopify tech team for 80% less than in-house teams</h1>
                    <p className='pera__style'>All-inclusive Shopify team: setup, custom coding, IT, and more, all for a flat monthly price</p>
                    <div>
                        <div className='hero__btn__section'>
                            <div>
                                <CalendlyPopup classes={"btnnoraml_style bg__black"} text="Book a Demo" url={info?.commercesquadCalendlyUrl} />
                            </div>
                            <div>
                                <HashLink to={"#pricing"}>
                                    <Normalbtn label="See Pricing" />
                                </HashLink>
                            </div>
                        </div>
                        <p className='pause__text'>Pause or cancel anytime.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default index