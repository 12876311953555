import React from 'react'
import { InlineWidget } from "react-calendly";
import { info } from '../../constants/data';
import './style.scss'

const Index = () => {
  return (
    <div className='custom__container calendly__widget__container'>
         <InlineWidget url={info?.commercesquadCalendlyUrl} />
    </div>
  )
}

export default Index