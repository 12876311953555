import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { routes } from './routes'
import { getToken } from '../functions/localstorage';

function AppRouter() {

    const PrivateRoute = ({ children }) => {
        const token = getToken();
       
        return token ? children : <Navigate to="/" replace />;
      };

    return (
        <BrowserRouter>
            <Routes>
                {routes.map(({ path, element, privateRoute }, index) => {
                    return <Route key={index} path={path} element={privateRoute ? <PrivateRoute children={element} /> : element} />
                })}
            </Routes>
        </BrowserRouter>
    )
}

export default AppRouter;
