import React, { useEffect, useState } from 'react'
import './style.scss'
import { Fotter, ThanksHero, ThanksVideoSection } from '../../layouts'
import { CalendlyWidget, Navber } from '../../components'
import { getUserDetails } from '../../api/stripe'
import { thanksPageVisit } from '../../functions/fbp'

const Index = () => {

    const [userData, setUserData] = useState({});
    const params = new URLSearchParams(window.location.search);

    useEffect(() => {
        thanksPageVisit()
        getStripeUserData()
    }, [])

    const getStripeUserData = async () => {
        const sessionId = params.get("session_id")
        if (sessionId && sessionId !== "") {
            const userData = await getUserDetails(sessionId);
            if (!userData.error) {
                setUserData(userData?.data?.data ?? {})
            }
        }
    }

    return (
        <div className='w-100 position-relative overflow-hidden'>
            <Navber onlyLogo />
            <ThanksHero email={userData?.email} />
            <CalendlyWidget />
            <ThanksVideoSection />
            <Fotter />
        </div>
    )
}

export default Index
