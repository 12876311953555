import React from 'react'
import { PopupButton } from "react-calendly";
import { info } from '../../constants/data';

const Index = ({ text, classes, url }) => {
    return (
        <PopupButton
            url={url ?? info?.commercesquadCalendlyUrl}
            rootElement={document.getElementById("root")}
            text={text ?? "Book a Demo"}
            className={classes ?? ""}
        />
    )
}

export default Index