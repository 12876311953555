import React from 'react'
import './style.scss'
import { ComusIcon } from '../../assets/icons/Icons'
function index({data}) {

    return (
        <div className='reviw__card__box'>
            <div>
                <img src={data.img} />
            </div>
            <div>
                <ComusIcon />
                <p className='review__card__text'>{data.text1}</p>
                <p className='reviw__description'>
                    {data.text2}
                </p>
            </div>
        </div>
    )
}

export default index