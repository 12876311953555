import React from 'react'
import AppRouter from './config/router';
import './App.scss';
import { ConfigProvider } from 'antd';

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: '#191A23',
          borderRadius: 2,
        },
      }}
    >
      <AppRouter />
    </ConfigProvider>
  )
}

export default App
