import { Logo, Shopify, Thanks } from "../views";
export const routes = [
    {
        path: "/",
        element: <Shopify />,
    },
    {
        path: "/thanks",
        element: <Thanks />,
    },
    {
        path: "/logo/svg",
        element: <Logo />,
    },
]